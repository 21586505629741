.table-container {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  margin: 20px 0;
}

.table-th,
.table-td {
  padding: 10px;
  border: 1px solid #ccc;
}

.table-thead {
  background-color: #f2f2f2;
}

.table-tbody .table-tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-select {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background-color: #f2f2f2;
  color: #333;
  cursor: pointer;
}