.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: var(--theme-color);
  max-width: 200px;
  width: 200px;
  height: 100vh;
  position: fixed;
  border-right: 1px solid black;
  z-index: 3;

}

.nav-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.badge-position {
  padding: 5px 0;
}

.nav-header {
  border-bottom: 1px solid lightgray;

}

.logo {
  width: 100%;
  height: 100%;
}

.nav-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* permet au logout-container de prendre tout l'espace restant en hauteur */
  justify-content: flex-end;
  /* aligne le logout-container en bas de la nav-content */
}

/* inside map */
.drawer-nav {
  width: 100%;
  display: flex;
}

.drawer-row {
  width: 100%;
}

.drawer-link-box {
  margin: 0.5rem;
}

.drawer-link {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 0.8rem;
}

.drawer-nav span {
  margin-left: 10px;
}

.icon {
  font-size: var(--icon-open);
  padding: 1px 0;
}

/* hover */

.drawer-row:hover {
  background-color: var(--hover-color);
  color: white;
}

.logout-container {
  margin-top: auto;

}