.dev-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dev-form-box {
  width: 100%;
}

.dev-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 10px;
}

.dev-form-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.dev-form-label {
  width: 100%;
}

.dev-form-input {
  width: 100%;
  height: 2rem;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.5rem;
}

.dev-form-btn {
  margin-top: 10px;
  padding: 1rem;
  border-radius: 10px;
  border: 0.5px solid black;
  background-color: #fff9ec;
  cursor: pointer;
}

.dev-form-btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}