.badge-notif-container {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  z-index: 5;
}

.badge-notif-wrapper {
  color: white;
}