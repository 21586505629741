.header_list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: var(--theme-color);
  width: 100%;
}



.link_dev {
  text-decoration: none;
  color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid black;

}

.link_dev:hover {

  background-color: var(--hover-color);
}