.create-project-page {
  height: 100vh;
  padding: 20px;
}


.create-project-header {
  background-color: var(--theme-color);
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 30px;
}

.create-project-title {
  margin-bottom: 30px;
  padding: 0;
}

.create-project {
  margin: 0;
  padding: 0;
}

.create-project-formulaire {
  width: 100%;
  display: flex;
  align-items: center;
}

.create-project-tab {
  width: 100%;
}

.create-project-btn {
  width: 100%;
}

.create-btn {
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--theme-secondary);
  cursor: pointer;
}

.create-btn:hover {
  background-color: var(--theme-secondary-hover);
}

.piece {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  /* Couleur de fond de la pièce */
  clip-path: polygon(15% 0%, 85% 0%, 100% 15%, 100% 85%, 85% 100%, 15% 100%, 0% 85%, 0% 15%);
  /* Forme de la pièce avec des arrondis */
  border-radius: 20px;
  /* Ajoute des arrondis aux coins de la pièce */
}

.create-project-dossier {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.edit-project-label {
  margin-top: 10px;
}