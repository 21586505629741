.page-container {
  padding: 20px;
}

.page-title {
  margin: 0;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.form-box {
  display: flex;
  border-radius: 10px;
  width: 90%;
  justify-content: center;
  -webkit-box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.20);
  box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.20);
  background-color: white;
  padding: 10px;
}

.form {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100%;
  border: 1px solid black;
  padding: 1rem;
  border-radius: 10px;
}

.input-form {
  margin: 1rem 0;
  width: 80%;
}

.input {
  width: 100%;
  height: 2rem;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.5rem;
}

.user-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.btn-form {
  background-color: var(--btn-download);
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
}