.goback_popover_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.goback_btn:first-child {
  margin-right: 10px;

}

.goback_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.goback_glyphe {
  font-size: 2em;
  margin-right: 15px;

}

.true {
  color: var(--btn-valide);
}

.false {
  color: rgba(255, 77, 77, 0.847);
}

.goback_btn:hover {
  background-color: var(--hover-color);
}