ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li:not(:last-child)::after {
  content: " > ";
  margin: 0 3px;

}

li:last-child {
  font-weight: bold;
}

.breadcrumb_link {
  text-decoration: none;
  color: black;
}

.breadcrumb_link:hover {
  text-decoration: underline;
}