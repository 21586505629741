.teams_mapping {
  padding: 20px;
}

.dico_navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2rem;
}

.dico_version {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  width: 100%;
  cursor: pointer;
}

.dico_version.active {
  background-color: var(--theme-color);
  font-weight: bold;
  cursor: pointer;
}

.var_input_mapping {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none
}

.var_select_mapping {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none
}

.teams_mapping_btn {
  margin-top: 20px;
}