.table-tranche {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
}

.table-tranche-thead {
  background-color: var(--theme-color);
}

.table-tranche-row:nth-child(odd) {
  background-color: #f2f2f2;
  text-align: center;
}

.table-tranche-row:nth-child(even) {
  background-color: white;
  text-align: center;
}

.foot {
  background-color: var(--hover-color) !important;
  font-weight: bold;
  font-style: italic;
}