.varHeader_page {
  padding: 20px;
}

.header_sticky {
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
}

.varHeader_table {
  width: 100%;
  padding: 10px 0;
}

.varHeader_head {
  padding: 10px !important;
}

.table {
  width: 100%;
}

.var_header_btn {
  margin-top: 10px;
}

.var_header_btn {
  /* position: sticky;
  left: 0;
  z-index: 0; */
}


.varHeader_value {
  width: 50%;
}

.varHeader_select {
  display: flex;
  justify-content: center;
}

.col {
  width: 50%;
}