.header-projet-container {
    margin: 0;
    padding: 0;
}

.header-projet-title {
    padding: 0;
    margin: 0;
}

.header-projet-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

}