.createVarPage_container {
  padding: 20px
}

.var_page_newvar {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: fit-content;
}