/* Page Wrapper/Container Style */
.container {
  margin-top: 20px;
  width: 100%;
}

/* Responsive Table Style */
.responsive-table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 9px 8px 15px -4px rgba(135, 135, 135, 0.53);
}

.thead_row {
  border: 1px solid black;
}

.thead_cell {
  font-size: 12px;
  padding: 10px;
}

.tbody_row {
  border: 1px solid black;
  font-size: 12px;
}

.tbody_row:nth-child(odd) {
  background-color: var(--theme-color);
}

.tbody_row:hover {
  background-color: var(--hover-color);
}

.responsive-table .tr_text {
  text-align: center;
}

.tbody_cell {
  text-align: center;
  border: 1px solid black;
  padding: 10px;
  text-decoration: none;
}

.row_link {
  text-decoration: none;
  color: black;
}

.row_link:hover {
  text-decoration: underline;
}

.responsive_select {
  border: none;
  background-color: #f5f5f5;
  text-align: center;
  font-weight: bold;
}

.tprojet {
  display: flex;
  border: none;
}

.delete-icon {
  cursor: pointer;
}
