.download-btn {
  background-color: var(--btn-download);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.download-btn:hover {
  background-color: var(--theme-tertiary);

}

.download-btn:disabled {
  background-color: var(--btn-download);
  cursor: not-allowed;

}