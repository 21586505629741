.version_container {
  width: 100%;
  display: flex;

}

.version_app {
  color: black;
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

.login_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image_container {
  margin-bottom: 2rem;
  display: flex;

  justify-content: center;
}

.image_ja {
  width: 70%;
  height: 70%;
}

.login_box {
  width: 100%;
  max-width: 500px;
  border: 1px solid var(--tertiary-color);
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--white-color);
}

.login_header {
  text-align: center;
}

.login_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login_input {
  width: 100%;
  max-width: 400px;
  margin: 1rem 0;
}

.login_button {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.login_error span {
  color: red;
  font-style: italic;
}

.reset_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2/3;

}

.reset_box_input,
.reset_input {
  display: flex;
  width: 100%;
}

.reset_box_input>input {
  padding: 10px;
}

.confirm_message {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.confirm {
  font-size: 10px;
}

.reset_btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.forgotten_password_container {
  width: fit-content;
}

.forgotten_password_text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.login_btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}