.toast {
  position: fixed;
  top: 20px;
  right: 40%;
  width: 250px;
  height: 60px;
  z-index: 1000;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  -webkit-box-shadow: 9px 13px 15px -4px rgba(0, 0, 0, 0.72);
  box-shadow: 9px 13px 15px -4px rgba(0, 0, 0, 0.72);
}

.toast--error {
  background-color: #540b0e;
  color: white;
}

.toast--default {
  background-color: #000000;
}

.toast--success {
  background-color: #d0f4de;
}

.toast--warning {
  background-color: #fcf6bd;
  color: black;
}

.toast--info {
  background-color: #a9def9;
  color: black;
}

.toast_message {
  padding: 0 10px;
  font-weight: bold;
}

.toast_icon {
  border-right: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10px;
}
