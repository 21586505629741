.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--theme-color);
}


.accordion-title {
  padding: 1rem;
  ;
}

.accordion-title,
.accordion-content {
  margin-bottom: 2px;
  position: relative;
}

.accordion-content {}

.accordeon-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--theme-color);
  color: var(--white);
  padding: 1rem;
  margin-bottom: 2px;
  cursor: pointer;
}

.notif_link {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.accordeon-body:hover {

  background-color: var(--hover-color);
}