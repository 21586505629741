.loader_page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.all-projects-page {
  padding: 20px;
}

.all-project-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.all_project_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.all-project-menu {
  width: fit-content;
  display: flex;
  color: black;
  text-align: center;
  font-size: 24px;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
}



.notification {
  margin-right: 20px;
  cursor: pointer;
}

.all-project-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.all-project-wrapper {
  width: 100%;
  border: 1px solid black;
}

.all-project-direction {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: black;
}

.all-project-row {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.prepa {
  min-width: 20%
}

.visible {
  min-width: 20%
}

.status {
  min-width: 10%
}

.dossier {
  min-width: 35%;
}

.project-item:nth-child(odd) {
  background-color: var(--theme-color);
}

.project-item:nth-child(even) {
  background-color: var(--hover-color);
}

.all-project-badge-notif {
  position: absolute;
  top: 5px;
  left: 27px;
}

.prepa-screen {
  padding: 20px;
}

.standby {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.standby_arrow {
  margin-right: 10px;
}

.more {
  padding: 5px;
}

.pagination-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-bar button {
  padding: 8px 12px;
  margin: 0 4px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.pagination-bar button.active {
  font-weight: bold;
}

.pagination-bar button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}