.var_container {
  padding: 20px;
}

.var_table {
  width: 100%;
}

.var_cell {
  border: 1px solid black;
}

.var_td,
.var_th {
  border: 1px solid rgb(190, 190, 190);
  padding: 10px;
  height: 30px;

}


.var_head_row {
  text-align: center;
}

.var_body_row:hover {
  background-color: rgb(240, 240, 240);
}

.var_btn {
  background-color: transparent;
  border: none;
}

.var_input_mapping {
  border: none;
  background-color: transparent;
  width: 100%;
}

.mapping_table {
  /* margin-top: 50px; */
}