.popover-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popover-paper {
  position: relative;
  transform: translate(20%, 0);
  background-color: white;
  padding: 20px;
  width: 30%;
  border-radius: 10px;
}

.popover-wrapper {
  text-align: center;
}

.popover-close-btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.popover-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* Popover close */

.popover-close {
  display: none;
}

.popover_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}