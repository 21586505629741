.multiStep {
  padding: 20px;
  overflow-x: hidden;
}

.edit_project {
  display: flex;
  align-items: center;
  text-align: center;
  width: fit-content;
  color: black;
}

.edit_project>p {
  margin-left: 10px
}

.edit_project:hover {
  cursor: pointer;
  text-decoration: underline;

}