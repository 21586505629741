.card-container {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.card-wrapper {
  position: relative;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 4px 6px 15px 0px rgba(149, 149, 149, 0.66);
  background-color: var(--white-color);
}

.card-number {
  position: absolute;
  top: -10px;
  background-color: var(--theme-color);
  border-radius: 50%;
  border: 1px solid rgb(109, 109, 109);
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  color: white;
}

.card-information {
  position: absolute;
  top: 10px;
  right: 10px;
}

.help:hover {
  cursor: help;
}

.popover-info-container {
  position: absolute;
  top: 0;
  right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}