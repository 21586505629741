.card-icon-container {
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;

}

.icon {
  margin-right: 10px;
}

.dashboard-container {
  padding: 20px;
}



.link-btn {
  text-decoration: none;
  color: black;
  padding: 10px 10px;
}

.link-btn:hover {
  background-color: var(--btn-download);
  border-radius: 5px;
}