.input_container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input_container:focus-within label {
  transform: translate(0, -40%) scale(0.8);
  background-color: #ffffff;
}

.input_container .filled {
  transform: translate(0, -40%) scale(0.8);
  background-color: #ffffff;
  padding: 0 4px;
}

.input_container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 15px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: var(--smooth-grey);
  font-size: 16px;
  line-height: 1;
  left: 16px;
  white-space: nowrap;
}

.input_container .floatinput {
  border-radius: 4px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input_container input:focus,
.text_area:focus {
  border: 2px solid var(--theme-color);
}

.floatinput,
.select_input {
  border: 1px solid var(--theme-color);
  padding: 0 16px;
  min-width: 180px;
  height: 50px;
}

.filled {
  background-color: var(--white-color);
}

.text_area {
  border: 1px solid var(--hight-grey);
  padding: 16px;
  border-radius: 5px;
  outline: none;
}

.select_input {
  border: 1px solid var(--hight-grey);
  height: 64px;
  border-radius: 5px;
  outline: none;
  appearance: none;
  background-position: calc(100% - 16px) center;
  background-repeat: no-repeat;
  background-size: 25px;
}

.password-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  outline: none;
  color: #333;
}
