body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}


:root {
  --primary-color: #586994;
  --secondary-color: #7d869c;
  --tertiary-color: #a2abab;
  --quaternary-color: #b4c4ae;
  --theme-color: #3b8ec6;
  --theme-secondary: #3b9991;
  --theme-secondary-hover: #04766e;
  --theme-tertiary: #ababab;
  --hover-color: #75bae8;
  --white-color: #ffffff;
  /* button */
  --btn-valide: #d9ed92;
  --btn-download: #dee2e6;
  --btn-download-hover: #3d5577;
  --btn-valide-hover: #c5d86d;
  --generic-btn: #dee2e6;
  --generic-btn-hover: #3d5577;
  /* size */
  --drawer-width: 200px;
  --icon-open: 30px;
  --icon-close: 20px;
}