.error {
  font-weight: bold;
  font-size: 1.5rem;
  color: red;
  background-color: var(--quaternary-color);

}

.error_log {
  padding: 16px 0;

}

.error_text {
  margin: 0;
  font-size: 20px;
  font-weight: bold;

}