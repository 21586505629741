.validate-btn {
  background-color: var(--btn-valide);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.validate-btn:hover {
  background-color: var(--btn-valide-hover);

}

.generic-btn:disabled {
  background-color: var(--btn-valide);

  cursor: not-allowed;
}