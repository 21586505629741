.profile_page {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.profile_badge_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 80px;
}

.profile_badge_box {
  width: 100%;
  min-height: fit-content;
  padding: 20px;
  border: none;
  box-shadow: 4px 6px 15px 0px rgba(149, 149, 149, 0.66);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);

}

.profile_badge {
  width: 200px;
  height: 200px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-color);


}

.profile_badge_text {
  font-weight: bold;
  color: white;
  font-size: 60px;
}

.profile_form {
  width: 100%;
  height: fit-content;
  padding: 20px 0;
  border: none;
  box-shadow: 4px 6px 15px 0px rgba(149, 149, 149, 0.66);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: var(--white-color);

}

.profile_input {
  margin-bottom: 20px;
}

.profile_text {
  display: flex;
  flex-direction: column;
}

.text_bold {
  margin: 10px 0;
  font-weight: bold;
}

.text_italic {
  margin: 0;
  font-style: italic;

}