.space-btn {
  display: flex;
  justify-content: space-between;
}

.modal-xlsx {
  width: 90%;
  padding: 0 20px;
  overflow: auto;
}

.legend {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.gestion_erreur_btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.modal_xlsx_sticky {
  background-color: white;
  z-index: 1;
}

.button_gap {
  display: flex;
  flex-direction: row;
}

.link_btn {
  background-color: var(--btn-download);
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 14px;
}