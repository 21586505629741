.open_pop {
  min-width: fit-content;
  max-width: 200px;
  height: fit-content;
  background: rgb(236, 236, 236);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 6px 15px 0px rgba(149, 149, 149, 0.66);
  border-radius: 10px;
  padding: 10px 20px;
}

.close_pop {
  display: none;
}