.load_popover_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.load_popover_paper {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%);
  background-color: white;
  padding: 20px;
  width: 30%;
  border-radius: 10px;
}



.load_popover_body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


/* load_Popover_close */

.load_popover_close {
  display: none;
}