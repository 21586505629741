.input-icons i {
    position: absolute;
    display: flex;
}

.input-icons {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.icon {
    padding: 10px;
}

.input-field {
    padding: 10px;
    text-align: flex-start;
    min-width: 200px;
}