.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;

}

.col {

  text-align: center;
}

.table td {
  border: 1px solid #000;
}

.table th {
  border-left: 1px solid black;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

.table th {
  padding: 0px 10px;
  background-color: #8fbc94;
  color: white;
  z-index: 0;
}

.thead {
  position: sticky;
  top: 200px;
  z-index: 0;
}

.header-row {

  left: 0;
}

.button_gap {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.gap {
  margin-right: 30px;
}

.arrow_btn {
  cursor: pointer;
}