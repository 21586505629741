.row-container {
  width: 100%;
}

.row-direction {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: var(--text-color);
}

.row-direction:nth-child(even) {
  background-color: var(--theme-color);
}

.row-direction:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}

.row-item {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.file,
.status {
  min-width: 200px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-wrap: nowrap;
}

.projet {
  min-width: 200px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-wrap: nowrap;
}

.preparator {
  min-width: 200px;
  max-width: 200px;
}

.button-donwload {
  border: none;
  background-color: transparent;
}


.header:hover {
  background-color: transparent;
}