.error_guid_body:not(:last-child) {
  margin-bottom: 10px;
}

.error_guid_number {
  font-size: 20px;
  font-style: italic;
  text-decoration: underline;
}

.error_guid_rule {
  font-size: 16px;
}

.error_guid_divider {
  width: 100%;
  height: 1px;
  background-color: black;
  margin-top: 5px;
}

.error_guid {
  padding: 20px
}