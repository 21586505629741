.card_slider_container {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  min-width: fit-content;
  min-height: fit-content;
  border: 1px solid #000;
  padding: 0 10px;
  margin: 0 10px;
}

.card_slider_wrapper {}

.card_slider_title {
  text-align: center;
}

.card_slider_body {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.card_slider_content {
  margin: 0 5px;
  text-align: center;
}