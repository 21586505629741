/* .formulaire {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

} */

.title_time {
  margin: 20px 0;
}

.page_container_time_form {
  padding: 20px;
}

.left_col,
.right_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

}

.left_col {
  margin-right: 10px;
}


.form_row {
  width: 100%;
}

.time_col {
  margin-right: 10px;
  margin-bottom: 10px;

}

.time_btn {
  width: 100%;
}

.time_submit {
  width: 100%;
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  height: 40px;
  cursor: pointer;
}

.time_submit:hover {
  background-color: grey;
}

.nav_dico {
  display: flex;
  flex-direction: row;
}

.formulaire {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
}

.toto {
  width: calc(50% - 10px);
  /* ajuster la largeur selon vos besoins */
  margin-bottom: 20px;
}