.tranche_resume {
  display: flex;
  flex-direction: row;
}

.tranche_resume_item {
  display: flex;
  margin: 0 5px;
  text-align: center;
  flex-direction: row;
}

.tranche_resume_item:nth-child(1) {
  margin-right: 10px;
}

.tranche_item {
  margin-right: 10px;
}

.slider_container {
  display: flex;
  flex-direction: row;


  overflow-x: auto;
}

.download_synthese {
  border: none;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;

}

.download_link {
  color: black;
}

.input_modal_direction {
  display: flex;
  flex-direction: column;
}

.input_modal {
  padding: 16px 0 16px 5px;
  border: 1px solid black;
  border-radius: 10px;
}