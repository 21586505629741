.legend-container {
  margin-bottom: 5px;
}

.legend-item-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
}

.legend-item {
  width: 50px;
  height: 25px;
  margin-right: 10px;
  border-radius: 5px;
}

.isError {
  background-color: #ff0000;
}

.isCheck {
  background-color: #bde0fe;
}

.isEdited {
  background-color: #ffa500;
}

.legend-item-align {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}