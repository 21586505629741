.upload-btn {
  background-color: var(--btn-download);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.upload-filename {
  font-size: 12px;
}

.upload-btn:hover {
  background-color: var(--tertiary-color);
}