.correction_container {
  padding: 20px;
}

.correction_legend_container {
  height: 220px;
}

.correction_header_sticky {
  position: fixed;
  top: 0;
  height: fit-content;
  padding: 20px;
  left: var(--drawer-width);
  z-index: 1;
  background-color: #f5f5f5;
  width: 100%;
}

.correction_button_container {
  display: flex;
}

.correction_gap {
  margin-right: 20px;
}

.correction_arrow {
  position: fixed;
  right: 0;
  bottom: 0;
}

.help_btn {
  background-color: var(--btn-download);
  height: 100%;
  padding: 0 20px;
  border-radius: 10px;
  border: none;
  cursor: help;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
