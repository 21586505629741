.row-price {
  display: flex;
  width: 100%;
  justify-content: center;
}

.dico {
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 10px;
}

.column {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column:first-child {
  margin-right: 20px;
}

.price {
  width: 50%;
  border-radius: 3px;
  margin-bottom: 10px;
}

label {
  margin-bottom: 5px;
}