.first-step {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.first-step-select {
  background-color: var(--theme-color);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.first-step-select-box {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}

.first-step-select-comp {
  border-radius: 5px;
}

.first-step-valid {}

.first-step-btn {
  height: 30px;
  width: 100%;
  background-color: transparent;
  color: var(--white-color);
  border: none;
  margin-top: 10px;
  cursor: pointer;
}

.envoi-prepa-table {
  overflow: auto;
}

.message {
  font-size: 1.2rem;
  font-weight: 600;
}