.select-prepa {
  /* border: 1px solid var(--theme-color);
  padding: 0 16px;
  width: 100%;
  height: 50px;
  */
  min-width: 250px;
  z-index: 100;
}

/* .select_prepa_label {
  margin-bottom: 10px;
} */