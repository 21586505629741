.mapping_header {
  padding: 20px
}

.card_header_body {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
  margin-bottom: 2em;
}

.card_select {
  display: flex;
  flex-direction: column;
}