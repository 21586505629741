.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}

.btn_close {
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;

}

.modal-close {
  display: none;
}

.modal-paper {
  position: relative;
  background-color: white;
  border-radius: 10px;
  width: 90%;
  height: 90%;
}

.modal-box {
  display: flex;
  overflow: auto;
  height: 90%;
  width: 100%;
}

.modal {
  width: 100%;
  padding: 0 20px;
}