.logout-row {
  width: 100%;
}

.logout-link-box {
  margin: 0.5rem;
}

.logout-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  width: 100%;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: rgb(173, 28, 28);
  border-radius: 10px;
  font-weight: bold;
}

.logout-nav span {
  margin-left: 10px;
}

.logout-link:hover {
  background-color: red;

}