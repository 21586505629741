.custom-select {
  position: relative;
  width: 100%;
}

.selected-option {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: fit-content;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.search-input {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
}

.search-result {
  padding: 10px;
  cursor: pointer;
}

.search-result:hover {
  background-color: #eee;
}